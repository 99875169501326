import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { envConfig } from '../../constants'

class ComplaintInfo extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmitCallback = props.onSubmitCallback
    }

    verifyCaptchaToken = (recaptchaToken) => {
        fetch(envConfig.url.API_URL + '/complaint_info', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                g_recaptcha_response: recaptchaToken
            })
        }).then((data) => {
            if (data.ok) {
                this.onSubmitCallback(true, recaptchaToken)
            } else {
                alert('Something went wrong. Please try again')
                this.onSubmitCallback(false, recaptchaToken)
            }
        }).catch((error) => {
            console.error('Error:', error);
            alert('Something went wrong. Please try again')
            this.onSubmitCallback(false, recaptchaToken)
        });
    };

    async handleSubmit(e) {
        e.preventDefault();
        let result = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
        console.log(result)
        this.verifyCaptchaToken(result)
    }

    render() {
        return (
            <div className="container complaint-app">
                <div className="section-heading">
                    <h1>DMCA Notice Form</h1>
                    <div className="divider"/>
                </div>
                <p >
                    If your copyright-protected work was posted without authorization, you may submit a copyright
                    infringement notification.
                </p>
                <br/>
                <p >
                    If you choose to submit a copyright takedown request, remember that you're starting a legal process.
                    Do not make false claims. Misuse of this process may result in legal consequences.
                </p>
                <form
                    name="infoForm"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <div className="form-group">
                        <button className="form-control dmca-submit">
                            {/*id="submit" value="Submit">*/}
                            Submit a copyright complaint
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ComplaintInfo