const prod = {
    url: {
        API_URL: 'https://aktis-249016.appspot.com', //TODO: fill prod value
    }
};
const dev = {
    url: {
        API_URL: 'http://localhost:8080'
    }
};

function define() {
    if (process.env.NODE_ENV === 'production') {
        return prod
    } else if (process.env.REACT_APP_ENV === 'production') {
        return prod
    } else {
        return dev
    }
}

export const envConfig = define()