import React from 'react';

class Details extends React.Component {
    render() {
        return (
            <div className="container details-app ">
                <div className="section-heading inverse">
                    <h1>Details</h1>
                    <div className="spacer"/>
                    <h2 className="gradient-header-low">AT Player is lightweight yet fully packed app</h2>
                </div>
                <div className="row inverse">
                    <p className="fa-inverse">
                        Free Music Player for YouTube: AT Player - best free music player and creative commons free music downloader. Offline MP3, FM Radio, 200 million+ downloads! Discover and listen to 300 million+ free songs! 🎧 Keep your music playing while using other apps 🌟 Note: this app downloads music for offline listening from Dropbox, Jamendo, and podcasts. YouTube music can be streamed but NOT downloaded. This app is NOT YouTube music downloader.
                    </p>
                    <p className="fa-inverse">
                        No.1 app in Trending Music & Audio 2023 💎
                    </p>
                    <p className="fa-inverse">
                        Music downloader takes download enabled music offline to play whenever you want with equalizer, variable speed, bass boost. Download 5 million+ songs with multithreaded MP3 downloader engine. Listen to offline music, FM Radio even when the screen is off.
                    </p>
                    <p className="fa-inverse">
                        Online streaming instantly plays from free music catalog any song you like. Listen to free music discovered by smart AI recommendation: personalized playlists for your taste. 💖 Millions of free songs, artists, playlists from YouTube for any mood or activity.
                    </p>
                    <p className="fa-inverse">
                        Discover new & trending free music in high-quality using search or music recognition with the best Free Music Player!
                    </p>
                    <p className="fa-inverse">
                        Totally free music 🎉 Totally free all the app features 🎉 No limited skips, no accounts, no purchases!
                    </p>
                    <p className="fa-inverse">
                        Lightweight and powerful Free Music Player improves 🚀 YouTube music videos with state of the art music player features like Chromecast, Dropbox, Podcasts, headset support, light/dark themes, sleep timer, equalizer, bookmarks, lyrics, playlists, etc.
                    </p>
                    <p className="fa-inverse">
                        Free Music Player multitasking experience: listen to YouTube free music with a floating player while texting, chatting, gaming, using maps and any other apps. Player lock mode and battery saver prevent accidental taps and save power. It helps to listen to the music without turning off your phone. (playing online music when the screen is off is not allowed by YouTube - use offline or radio tabs for that)
                    </p>
                    <p className="fa-inverse">
                        ⭐️ Enjoy free music with AT Player: <br></br>
                        • Search with suggestions for songs, FM radio stations, playlists, albums, artists, singles, covers, podcasts and remixes
                        • Identify music playing nearby
                        • Floating popup player for multitasking with an ability to change size and many other options
                        • Listen to other users' playlists or import your own playlists from YouTube
                        • Free music continuous play using AI recommendation, music recognition, or search based playlists
                        • Discover free music with tons of predefined genres: Popular Music, Hip Hop, Rock, Classical, R&B, Jazz, Disco, Country, etc
                        • Use Chromecast to play on TV
                        • Live music / FM radio streaming
                        • High-Quality video mode (wifi recommended)
                        • Music alarm
                        • Audio Books, Science Fiction, Romance, etc
                        • Powerful and tiny, optimized for low system resources usage, uses minimum possible wifi or mobile data
                        • Discover best free music by mood, activity, genres, daily updated top charts, weekly top playlists
                    </p>
                    <p className="fa-inverse">
                        ⭐️ Playlists: <br></br>
                        • YouTube playlists import
                        • Personalized playlists: favorites, history, recommend
                        • Share playlists and tracks
                        • Auto-backup
                    </p>
                    <p className="fa-inverse">
                        ⭐️ Music player: <br></br>
                        • Minimalistic and stylish design
                        • Shuffle/repeat
                        • 5 bands equalizer. Presets: Classical, Dance, Folk, Heavy Metal, Hip Hop, Jazz, Pop, Rock, Electronic, Small speakers, Flat, Normal
                        • Bass boost, virtualizer, reverb effects
                        • Local music background playback
                        • Rich audio video lossless music format support: mp3, flac, m4b, mp4, 3gp, mid, ogg, etc
                        • Variable speed playback
                        • Lyrics
                        • Headset Bluetooth controls
                        • Sleep timer
                        • Auto add all local music
                        • Album art downloader
                        • Battery saver with reduced brightness and accidental taps prevention
                        • Set as ringtone
                    </p>
                    <p className="fa-inverse">
                        This app: <br></br>
                        • provides downloading and streaming for non-commercial use only
                        • does not download YouTube videos
                        • does not cache YouTube content for offline playback
                        • does not play YouTube in the background
                        • does not play YouTube when the screen is turned off
                    </p>

                </div>
            </div>
        )
    }
}

export default Details