import React from 'react';
import update from 'immutability-helper';
import '../../css/dmca.css'
import {envConfig} from '../../constants'
import countries from '../../resources/countries.json'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from 'react-phone-number-input'
import {usePromiseTracker} from "react-promise-tracker";
import {trackPromise} from 'react-promise-tracker';
import { Oval as Loader } from 'react-loader-spinner';

class ComplaintForm extends React.Component {

    constructor(props) {
        super(props);
        let {onSubmitCallback, appId} = props
        this.onSubmitCallback = onSubmitCallback
        this.appId = appId

        this.formErrors = {}
        this.state = {
            formErrors: {},
            country_opts: [],
            token: '',
            form_screenshots: [],
            form_app_name_url_infringing: '',
            form_urls_infringing: '',
            form_copyright_urls: '',
            form_complaint_description: '',
            form_company_name: '',
            form_job_title: '',
            form_full_name: '',

            form_address_street: '',
            form_address_city: '',
            form_address_state: '',
            form_address_zip: '',
            form_address_country: 'US',
            form_contact_phone: '',
            form_contact_fax: '',
            form_contact_primary_email: '',
            form_contact_secondary_email: '',
            form_contact_company: '',

            form_cbx_intentions: false,
            form_cbx_accuracy: false,
            form_cbx_behalf: false,
            form_cbx_acknowledge: false,

            form_contact_signature: '',

            form_formErrors: {}
        }

    }

    componentDidMount() {

        let copts = []
        let results = countries
        for (let k = 0; k < results.length; k++) {
            copts.push(<option key={results[k].alpha_2} value={results[k].alpha_2}> {results[k].name} </option>);
        }
        this.setState(update(this.state, {country_opts: {$set: copts}}))
    }

    storeCaptchaToken = recaptchaToken => {
        this.setState(update(this.state, {token: {$set: recaptchaToken}}))
    };

    verifyCallback() {

        let formData = new FormData()
        const combinedAppAndUrls = `App Name: ${this.state.form_app_name_url_infringing} Content: ${this.state.form_urls_infringing}`;
        console.log(combinedAppAndUrls)
        formData.append('form_complaint_description', this.state.form_complaint_description)
        formData.append('form_copyright_urls', this.state.form_copyright_urls)
        formData.append('form_company_name', this.state.form_company_name)
        // todo when time permits restart the dmca-service and make it understand separate params for app/url
        //  and remove combinedAppAndUrls var
        formData.append('form_app_name_url_infringing', this.state.form_app_name_url_infringing)
        // formData.append('form_urls_infringing', this.state.form_urls_infringing)
        formData.append('form_urls_infringing', combinedAppAndUrls)
        formData.append('form_job_title', this.state.form_job_title)
        formData.append('form_full_name', this.state.form_full_name)
        formData.append('form_address_street', this.state.form_address_street)
        formData.append('form_address_city', this.state.form_address_city)
        formData.append('form_address_state', this.state.form_address_state)
        formData.append('form_address_zip', this.state.form_address_zip)
        formData.append('form_address_country', this.state.form_address_country)
        formData.append('form_contact_phone', this.state.form_contact_phone)
        formData.append('form_contact_fax', this.state.form_contact_fax)
        formData.append('form_contact_primary_email', this.state.form_contact_primary_email)
        formData.append('form_contact_secondary_email', this.state.form_contact_secondary_email)
        formData.append('form_contact_company', this.state.form_contact_company)
        formData.append('form_contact_signature', this.state.form_contact_signature)

        formData.append('form_cbx_intentions', this.state.form_cbx_intentions)
        formData.append('form_cbx_accuracy', this.state.form_cbx_accuracy)
        formData.append('form_cbx_behalf', this.state.form_cbx_behalf)
        formData.append('form_cbx_acknowledge', this.state.form_cbx_acknowledge)
        for (let i = 0; i < this.state.form_screenshots.length; i++) {
            formData.append(`form_screenshots_${i}`, this.state.form_screenshots[i])
        }
        formData.append('g_recaptcha_response', this.state.token)
        formData.append('appId', this.appId)
        trackPromise(
            fetch(envConfig.url.API_URL + '/complaint', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: formData
            }).then((data) => {
                if (data.ok) {
                    this.onSubmitCallback(true, this.state.token)
                } else {
                    alert('Something went wrong. Please try again')
                    this.onSubmitCallback(false, this.state.token)
                }
            }).catch((error) => {
                console.error('Error:', error);
                alert('Something went wrong. Please try again')
                this.onSubmitCallback(false, this.state.token)
            }));
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (!this.validateInput()) {
            return
        }
        let result = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
        this.storeCaptchaToken(result)
        this.verifyCallback()
    }

    renderSelectedFilesLabel = () => {
        if (this.state.form_screenshots.length === 0) {
            return (
                <div className="file-list">
                    <p>No Files selected</p>
                </div>
            )
        } else {
            return (
                <div className="file-list">
                    {Array.from(this.state.form_screenshots).map(f => <p key={f.name}> {f.name} </p>)}
                </div>
            )
        }
    }

    updateScreenshotList = e => {
        let files = e.target.files;
        if (this.maxSelectFile(e) && this.checkMimeType(e) && this.checkFileSize(e)) {
            this.setState(update(this.state, {form_screenshots: {$set: files}}))
        }
    }

    validatePhoneNumber = (field) => {
        let res = isValidPhoneNumber(this.state[field]) === true;
        if (!res) {
            this.formErrors[field] = true
        }
        return res
    }

    validateInput = () => {
        this.formErrors = {}
        let res = this._validateFormInput()
        return res
    }

    validateTextNotEmpty = (field) => {
        let val = this.state[field]
        let res = val && typeof val === 'string' || val instanceof String && val.length > 0;
        if (!res) {
            this.formErrors[field] = true
        }
        return res;
    }

    validateCheckbox = (field) => {
        let val = this.state[field]
        let res = val && typeof val === 'boolean' || val.length === true;
        if (!res) {
            console.log(field)
            this.formErrors[field] = true
        }
        return res;
    }

    validateEmail = (field) => {
        let val = this.state[field]
        let res = val && typeof val === 'string' || val instanceof String && val.length > 0;
        res = res && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)
        if (!res) {
            this.formErrors[field] = true
        }
        return res
    }

    validateScreenshots = (field) => {
        let val = this.state[field]
        let res = val && val.length > 0 && val.length <= 10
        if (!res) {
            this.formErrors[field] = true
        }
        return res
    }

    _validateFormInput = () => {

        let res = true;
        res = this.validateTextNotEmpty('form_copyright_urls') && res
        res = this.validateTextNotEmpty('form_complaint_description') && res
        res = this.validateTextNotEmpty('form_company_name') && res
        res = this.validateTextNotEmpty('form_app_name_url_infringing') && res
        res = this.validateTextNotEmpty('form_urls_infringing') && res
        res = this.validateTextNotEmpty('form_job_title') && res
        res = this.validateTextNotEmpty('form_full_name') && res
        res = this.validateTextNotEmpty('form_address_street') && res
        res = this.validateTextNotEmpty('form_address_city') && res
        res = this.validateTextNotEmpty('form_address_state') && res
        res = this.validateTextNotEmpty('form_address_zip') && res
        res = this.validateTextNotEmpty('form_address_country') && res
        res = this.validatePhoneNumber('form_contact_phone') && res
        res = this.validateEmail('form_contact_primary_email') && res
        res = this.validateTextNotEmpty('form_contact_company') && res
        res = this.validateTextNotEmpty('form_contact_signature') && res

        res = this.validateScreenshots('form_screenshots') && res

        res = this.validateCheckbox('form_cbx_intentions') && res
        res = this.validateCheckbox('form_cbx_accuracy') && res
        res = this.validateCheckbox('form_cbx_behalf') && res
        res = this.validateCheckbox('form_cbx_acknowledge') && res
        this.setState(update(this.state, {
            formErrors: {$set: this.formErrors},
        }))
        return res
    }

    getValue = (t) => {
        if (t.type === "checkbox") {
            return t.checked
        } else {
            return t.value
        }
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = this.getValue(e.target);
        this.setState(update(this.state, {
            [name]: {$set: value}
        }));
    }

    handlePhoneNumber = (value) => {
        this.setState(update(this.state, {
            form_contact_phone: {$set: value},
        }))
    }

    handleFaxNumber = (value) => {
        this.setState(update(this.state, {
            form_contact_fax: {$set: value},
        }))
    }

    render() {
        return (
            <div className="container complaint-app">
                <div className="section-heading">
                    <h1>DMCA Notice Form</h1>
                    <div className="divider"/>
                    {/*<p>Free Music Downloader & YouTube Player</p>*/}
                </div>

                <form
                    name="infoForm"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <div className="form-group dmca-section-long">
                        <span className="dmca-form-required">∗</span><label htmlFor="form_app_name_url_infringing">App Name
                        or URL with Allegedly Infringing Content: (Enter the name or URL of the app where the allegedly
                        infringing content appears)</label><br/>
                        <textarea name="form_app_name_url_infringing"
                                  className={this.state.formErrors['form_app_name_url_infringing'] ? 'dmca-description req-input' : 'dmca-description'}
                                  value={this.state.form_app_name_url_infringing} onChange={this.handleUserInput}
                                  maxLength={2000}/><br/>
                    </div>
                    <br/>
                    <div className="form-group dmca-section-long">
                        <span className="dmca-form-required">∗</span><label htmlFor="form_urls_infringing">URL of
                        allegedly infringing content to be removed: (Separate multiple URLs with commas)</label><br/>
                        <textarea name="form_urls_infringing"
                                  className={this.state.formErrors['form_urls_infringing'] ? 'dmca-description req-input' : 'dmca-description'}
                                  value={this.state.form_urls_infringing} onChange={this.handleUserInput}
                                  maxLength={2000}/><br/>
                    </div>
                    <br/>
                    <div className="form-group files dmca-section-long">
                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_screenshots">Screenshots: (you can attach several screenshots: use shift or
                        Ctrl/Cmd to attach several screenshots)</label>
                        {/*<input type="file" multiple name="screenshots" className="form-control" value={this.state.form_screenshots} onChange={this.updateList}/>*/}
                        <input type="file" multiple name="form_screenshots"
                               className={this.state.formErrors['form_screenshots'] ? 'form-control req-input' : 'form-control'}
                               onChange={this.updateScreenshotList}/>
                        {this.renderSelectedFilesLabel()}
                    </div>
                    <br/>
                    <div className="form-group dmca-section-long">
                        <span className="dmca-form-required">∗</span><label htmlFor="form_copyright_urls">URL of
                        the copyrighted work: (Separate multiple URLs with commas)</label><br/>
                        <textarea name="form_copyright_urls"
                                  className={this.state.formErrors['form_copyright_urls'] ? 'dmca-description req-input' : 'dmca-description'}
                                  value={this.state.form_copyright_urls} onChange={this.handleUserInput}
                                  maxLength={2000}/><br/>
                        <span className="dmca-form-required">∗</span><label htmlFor="form_complaint_description">Describe
                        the work allegedly infringed:</label><br/>
                        <textarea name="form_complaint_description"
                                  className={this.state.formErrors['form_complaint_description'] ? 'dmca-description req-input' : 'dmca-description'}
                                  value={this.state.form_complaint_description} onChange={this.handleUserInput}
                                  maxLength={2000}/><br/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_company_name">Copyright Owner
                        Name (Company Name):</label><br/>
                        <input type="text" name="form_company_name" value={this.state.form_company_name}
                               className={this.state.formErrors['form_company_name'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={64}/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_job_title">Your Title or Job
                        Position (What is your authority to make this complaint?):</label><br/>
                        <input type="text" name="form_job_title" value={this.state.form_job_title}
                               className={this.state.formErrors['form_job_title'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={100}/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_full_name">Your Full Legal
                        Name (A first and a last name, not a company name):</label><br/>
                        <input type="text" name="form_full_name" value={this.state.form_full_name}
                               className={this.state.formErrors['form_full_name'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={100}/>

                    </div>
                    <br/>
                    <div className="form-group dmca-section-long">
                        <span className="dmca-form-required">∗</span><label htmlFor="form_address_street">Street
                        Address:</label><br/>
                        <input type="text" name="form_address_street"
                               className={this.state.formErrors['form_address_street'] ? 'dmca-description req-input' : 'dmca-description'}
                               value={this.state.form_address_street} onChange={this.handleUserInput}
                               maxLength={400}/><br/>

                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_address_city">City:</label><br/>
                        <input type="text" name="form_address_city" value={this.state.form_address_city}
                               className={this.state.formErrors['form_address_city'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={100}/>

                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_address_state">State/Province:</label><br/>
                        <input type="text" name="form_address_state" value={this.state.form_address_state}
                               className={this.state.formErrors['form_address_state'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={64}/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_address_zip">ZIP/Postal
                        Code:</label><br/>
                        <input type="text" name="form_address_zip" value={this.state.form_address_zip}
                               className={this.state.formErrors['form_address_zip'] ? 'dmca-description req-input dmca-section-short' : 'dmca-description dmca-section-short'}
                               onChange={this.handleUserInput} maxLength={40}/><br/>

                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_address_country">Country:</label><br/>
                        <select name="form_address_country"
                                className={this.state.formErrors['form_address_country'] ? 'dmca-description req-input' : 'dmca-description'}
                                value={this.state.form_address_country} onChange={this.handleUserInput}>
                            {this.state.country_opts}
                        </select>

                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_contact_phone">Phone:</label><br/>
                        <PhoneInput
                            name="form_contact_phone"
                            placeholder="Enter phone number"
                            className={this.state.formErrors['form_contact_phone'] ? 'dmca-description dmca-section-short dmca-phone req-input' : 'dmca-description dmca-section-short dmca-phone'}
                            value={this.state.form_contact_phone}
                            onChange={this.handlePhoneNumber}/>

                        <label htmlFor="form_contact_fax">Fax:</label><br/>
                        <PhoneInput
                            name="form_contact_fax"
                            placeholder="Enter phone number"
                            className={this.state.formErrors['form_contact_fax'] ? 'dmca-description dmca-section-short dmca-phone req-input' : 'dmca-description dmca-section-short dmca-phone'}
                            value={this.state.form_contact_fax}
                            onChange={this.handleFaxNumber}/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_contact_primary_email">Primary
                        Email Address: </label><br/>
                        <input type="email" name="form_contact_primary_email"
                               value={this.state.form_contact_primary_email}
                               className={this.state.formErrors['form_contact_primary_email'] ? 'dmca-description req-input dmca-section-short' : 'dmca-description dmca-section-short'}
                               onChange={this.handleUserInput} maxLength={128}/><br/>

                        <label htmlFor="form_contact_secondary_email">Secondary Email Address:</label><br/>
                        <input type="text" name="form_contact_secondary_email"
                               value={this.state.form_contact_secondary_email}
                               className="dmca-description dmca-section-short"
                               onChange={this.handleUserInput} maxLength={128}/><br/>

                        <span className="dmca-form-required">∗</span><label
                        htmlFor="form_contact_company">Company:</label><br/>
                        <input type="text" name="form_contact_company" value={this.state.form_contact_company}
                               className={this.state.formErrors['form_contact_company'] ? 'dmca-description req-input dmca-section-short' : 'dmca-description dmca-section-short'}
                               onChange={this.handleUserInput} maxLength={100}/><br/>

                    </div>
                    <br/>
                    <div className="form-group dmca-accept dmca-section-long">
                        <span className="dmca-form-required">∗</span>
                        <input type="checkbox" name="form_cbx_intentions" checked={this.state.form_cbx_intentions}
                               className={this.state.formErrors['form_cbx_intentions'] ? 'req-input' : ''}
                               onChange={this.handleUserInput}/>
                        <label htmlFor="form_cbx_intentions"
                               className={this.state.formErrors['form_cbx_intentions'] ? 'req-input' : ''}>
                            I have a good faith belief that the use of the material in
                            the manner complained of is not authorized by the copyright owner, its agent, or the
                            law.</label><br/>
                        <span className="dmca-form-required">∗</span>
                        <input type="checkbox" name="form_cbx_accuracy" checked={this.state.form_cbx_accuracy}
                               className={this.state.formErrors['form_cbx_accuracy'] ? 'req-input' : ''}
                               onChange={this.handleUserInput}/>
                        <label htmlFor="form_cbx_accuracy"
                               className={this.state.formErrors['form_cbx_accuracy'] ? 'req-input' : ''}>
                            This notification is accurate.</label><br/>
                        <span className="dmca-form-required">∗</span>
                        <input type="checkbox" name="form_cbx_behalf" checked={this.state.form_cbx_behalf}
                               className={this.state.formErrors['form_cbx_behalf'] ? 'req-input' : ''}
                               onChange={this.handleUserInput}/>
                        <label htmlFor="form_cbx_behalf"
                               className={this.state.formErrors['form_cbx_behalf'] ? 'req-input' : ''}>
                            UNDER PENALTY OF PERJURY, I am authorized to act on behalf of
                            the owner of an exclusive right that is allegedly infringed.</label><br/>
                        <span className="dmca-form-required">∗</span>
                        <input type="checkbox" name="form_cbx_acknowledge" checked={this.state.form_cbx_acknowledge}
                               className={this.state.formErrors['form_cbx_acknowledge'] ? 'req-input' : ''}
                               onChange={this.handleUserInput}/>
                        <label htmlFor="form_cbx_acknowledge"
                               className={this.state.formErrors['form_cbx_acknowledge'] ? 'req-input' : ''}>
                            I acknowledge that under Section 512(f) of the DMCA any
                            person who knowingly materially misrepresents that material or activity is infringing may be
                            subject to liability for damages.</label><br/>

                        <span className="dmca-form-required">∗</span><label htmlFor="form_contact_signature">Typing your
                        full name in this box will act as your digital signature.</label><br/>
                        <input type="text" name="form_contact_signature" value={this.state.form_contact_signature}
                               className={this.state.formErrors['form_contact_signature'] ? 'dmca-description req-input' : 'dmca-description'}
                               onChange={this.handleUserInput} maxLength={100}/>
                        <LoadingIndicator/>
                    </div>
                </form>

            </div>
        );
    }

    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg']

        function checkFileType(x) {
            return type => files[x].type !== type;
        }

        for (let x = 0; x < files.length; x++) {
            // compare file type find doesn't match
            if (types.every(checkFileType(x))) {
                // create error message and assign to container
                err[x] = files[x].type + ' is not a supported format\n';
            }
        }
        ;
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error
            // discard all selected files
            if (err) {
                alert(err[z])
            }
            event.target.value = null
        }
        return true;
    }
    maxSelectFile = (event) => {
        let files = event.target.files
        // alert(`files.len ${files.length}`)
        if (files.length > 10) {
            const msg = 'Only 10 images can be uploaded at a time'
            event.target.value = null
            // toast.warn(msg)
            alert(msg)
            return false;
        }
        return true;
    }
    checkFileSize = (event) => {
        let files = event.target.files
        let maxSize = 20971520 //20mb
        let sizeSum = 0
        for (var x = 0; x < files.length; x++) {
            sizeSum += files[x].size
        }
        if (sizeSum > maxSize) {
            let err = `Maximum 20mb limit is reached\n`;
            alert(err)
            event.target.value = null
        }
        ;


        return true;
    }
}

const LoadingIndicator = props => {
    const {promiseInProgress} = usePromiseTracker();
    return promiseInProgress ?
        (<div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
            }}
        >
            <Loader type="ThreeDots" color="#0f2754" height="40" width="200"/>
        </div>) :
        (<button className="form-control dmca-submit"
                 id="submit" value="Submit">
            Submit
        </button>)
}

export default ComplaintForm