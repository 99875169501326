import React from 'react';
import features from "../img/features.png";

class Features extends React.Component {
    render() {
        return (
            <div className="container features-app ">
                <div className="section-heading inverse">
                    <h1>Features</h1>
                    <div className="spacer"/>
                    <h2 className="gradient-header-low">AT PLAYER: powerful music app chosen by 200 000 000+ users</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-4 fa-inverse">
                        <div className="media text-right feature">
                            <a className="pull-right" href="#">
                                <i className="fa fa-arrow-down fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body">
                                <h3 className="media-heading fa-inverse">Music Downloader</h3>
                                Not YouTube. Highly efficient download engine.
                            </div>
                        </div>
                        <div className="media text-right feature">
                            <a className="pull-right" href="#">
                                <i className="fa fa-youtube fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body">
                                <h3 className="media-heading fa-inverse">Music from YouTube</h3>
                                Import playlists from YouTube
                            </div>
                        </div>
                        <div className="media text-right feature">
                            <a className="pull-right" href="#">
                                <i className="fa fa-cloud fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body">
                                <h3 className="media-heading fa-inverse">FM Radio Stations</h3>
                                100000+ FM Radio Stations
                            </div>
                        </div>
                        <div className="media text-right feature">
                            <a className="pull-right" href="#">
                                <i className="fa fa-magic fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body">
                                <h3 className="media-heading fa-inverse">Offline music</h3>
                                Equalizer & sound effects
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <img alt="AT Player & Music Downloader" src={features} className="image-features" />
                    </div>
                    <div className="col-md-4 col-sm-4 fa-inverse">
                        <div className="media feature">
                            <a className="pull-left" href="#">
                                <i className="fa fa-fire fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body text-right-block-features">
                                <h3 className="media-heading fa-inverse">Background playback</h3>
                                Listen to music when your screen is off (Not YouTube)
                            </div>
                        </div>
                        <div className="media feature">
                            <a className="pull-left" href="#">
                                <i className="fa fa-music fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body text-right-block-features">
                                <h3 className="media-heading fa-inverse">Music recognition</h3>
                                Identify music playing nearby
                            </div>
                        </div>
                        <div className="media feature">
                            <a className="pull-left" href="#">
                                <i className="fa fa-search fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body text-right-block-features">
                                <h3 className="media-heading fa-inverse">Powerful search</h3>
                                Playlists, albums, artists, singles, covers, remixes
                            </div>
                        </div>
                        <div className="media feature">
                            <a className="pull-left" href="#">
                                <i className="fa fa-heart fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body text-right-block-features">
                                <h3 className="media-heading fa-inverse">Dark theme</h3>
                                Beautiful design with dark & light themes
                            </div>
                        </div>
                        <div className="media active feature">
                            <a className="pull-left" href="#">
                                <i className="fa fa-plus fa-2x fa-inverse"/>
                            </a>
                            <div className="media-body text-right-block-features">
                                <h3 className="media-heading fa-inverse">And much more!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Features